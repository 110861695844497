import { createGlobalStyle } from 'styled-components';
import { BaseTheme } from '@flexera/ui.component-library';

export const GlobalStyle = createGlobalStyle`
	html,
	body {
		h1,
		h2,
		h3,
		h4 {
			margin-top: 0;
		}
	}

	html,
	body,
	#shell-root {
		background-color: #f8f9fb;
		font-family: ${(props) => props.theme.fonts.primary};
		height: 100%;
		margin: 0;
		padding: 0;
		width: 100%;
		overflow-y: hidden;
	}

	.ui-fusion {
		.legacy_optima_wrapper {
			height: auto;
			&.is-fusion-explorer {
				height: calc(100vh - 64px);
			}
		}
	}

	#header-org-switcher {
		margin-right: 0;
	}

	#org-switcher-popover {
		margin-left: -4.5rem;
	}

	#profile-text-dropdown {
		margin-left: -5.1875rem;
	}

	#subcategory-slideout-panel {
        top: 0;
        height: 100%;
    }

    #network-slideout-panel {
        top: 0;
        height: 100%;
	}

    #analytical-slideout-panel,
	#new-dashboard-slideout-panel {
        top: 0;
        height: 100%;
	}

	#edit-group-beacon-details-slideout{
		top: 0;
        height: 100%;
	}

	#timeout-warning {
		.modal-close-icon {
			display: none;
		}
	}

	/** ag-grid filter menu styles: These are added here cause some filter menus are configured to be in the html body container instead of in the grid container */
	/* Filter pop over tab */
	.ag-tabs {
		background-color: ${BaseTheme.colors.light} !important;
		width: 280px;
		font-family: ${BaseTheme.fonts.primary} !important;
		.ag-tabs-header {
			display: none;
		}

		.ag-filter {
			margin: ${BaseTheme.space.md} ${BaseTheme.space.sm};
		}

		.ag-select {
			height: fit-content !important;
		}
		.ag-simple-filter-body-wrapper {
			padding: 0;
		}

		.ag-set-filter-list{
			height: auto;
			max-height: 144px;
		}

		.ag-mini-filter {
			margin: 0;
			margin-bottom: ${BaseTheme.space.sm};
			.ag-text-field-input-wrapper {
				&:before {
					position: absolute;
					left: ${BaseTheme.space.sm};
					top: ${BaseTheme.space.xs};
					content: url("data:image/svg+xml,%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 54.1 (76490) - https://sketchapp.com --%3E%3Ctitle%3Emd_search%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3Cpolygon id='path-1' points='3.00034774 3 20.0810229 3 20.0810229 20.0806477 3.00034774 20.0806477'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='md_search' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group' transform='translate(-0.000348, -0.000048)'%3E%3Crect id='Rectangle' x='0.000347741128' y='4.77411276e-05' width='24' height='24'%3E%3C/rect%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cg id='Clip-2'%3E%3C/g%3E%3Cpath d='M10.2101477,13.9461477 C7.16414774,14.4081477 4.59214774,11.8361477 5.05414774,8.79014774 C5.34214774,6.89214774 6.89314774,5.34214774 8.79014774,5.05414774 C11.8371477,4.59214774 14.4081477,7.16314774 13.9461477,10.2101477 C13.6581477,12.1071477 12.1071477,13.6581477 10.2101477,13.9461477 L10.2101477,13.9461477 Z M15.5001477,14.0001477 L14.7101477,14.0001477 L14.4301477,13.7301477 C15.6321477,12.3321477 16.2471477,10.4231477 15.9071477,8.38914774 C15.4421477,5.60814774 13.1191477,3.39214774 10.3191477,3.05014774 C6.08714774,2.53314774 2.53314774,6.08714774 3.05014774,10.3191477 C3.39314774,13.1181477 5.60714774,15.4421477 8.39014774,15.9071477 C10.4231477,16.2471477 12.3321477,15.6321477 13.7311477,14.4301477 L14.0001477,14.7101477 L14.0001477,15.5001477 L18.3001477,19.7911477 C18.6871477,20.1771477 19.3141477,20.1771477 19.6991477,19.7911477 L19.7911477,19.6991477 C20.1771477,19.3131477 20.1781477,18.6871477 19.7911477,18.3001477 L15.5001477,14.0001477 Z' id='Fill-1' fill='%23595959' mask='url(%23mask-2)'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
				}

				input {
					padding-left: 2.5rem !important;
					font-family: ${BaseTheme.fonts.primary};
					font-size: ${BaseTheme.fontSizes.sm};
					line-height: ${BaseTheme.lineHeights.md};
					height: 40px;
					border: ${BaseTheme.borders.sm} ${BaseTheme.colors.dark};
					box-sizing: border-box;
					border-radius: 4px;
					&:hover {
						border: ${BaseTheme.borders.sm} ${BaseTheme.colors.tertiary};
					}

					::placeholder {
						color: ${BaseTheme.colors.text};
					}

					&:focus,
					&:active {
						border: ${BaseTheme.borders.md} ${BaseTheme.colors.blue50};
					}
				}
			}
		}

		.ag-picker-field-wrapper {
			border: ${BaseTheme.borders.sm} ${BaseTheme.colorPalettes.primary.gray};
			box-sizing: border-box;
			border-radius: 4px;
			height: 34px;
			padding: ${BaseTheme.space.xs} ${BaseTheme.space.sm};
			margin-bottom: ${BaseTheme.space.sm};

			.ag-picker-field-display {
				color: ${BaseTheme.colorPalettes.primary.black80};
				font-family: ${BaseTheme.fonts.primary};
				font-size: ${BaseTheme.fontSizes.sm};
				line-height: ${BaseTheme.lineHeights.md};
			}
		}
		.ag-filter-virtual-list-item {
			margin-bottom: ${BaseTheme.space.xs};
		}

		.ag-text-field-input-wrapper {
			input {
				padding-left: 2.5rem;
				font-family: ${BaseTheme.fonts.primary};
				font-size: ${BaseTheme.fontSizes.sm};
				line-height: ${BaseTheme.lineHeights.md};
				height: 40px;
				border: ${BaseTheme.borders.sm} ${BaseTheme.colors.dark};
				box-sizing: border-box;
				border-radius: 4px;
				&:hover {
					border: ${BaseTheme.borders.sm} ${BaseTheme.colors.tertiary};
				}

				::placeholder {
					color: ${BaseTheme.colors.text};
				}

				&:focus,
				&:active {
					border: ${BaseTheme.borders.md} ${BaseTheme.colors.blue50};
				}
			}
		}
	}

	/* Checkbox styles */

	ag-theme-alpine .ag-root-wrapper-body .ag-checkbox-input-wrapper {
  background: none !important;
}

.ag-theme-alpine .ag-checkbox-input-wrapper input {
	&:focus{
		-webkit-box-shadow: 0 0 0 1px ${(props) =>
			props.theme.colors.blue30} !important;
		box-shadow: 0 0 0 1px ${(props) => props.theme.colors.blue30} !important;
	}
}

	.ag-checkbox-input-wrapper {
		&::after {
			color: transparent !important;
			height: 16px;
			width: 16px;
			border: 1px solid ${(props) => props.theme.colors.gray50};
			border-radius: 4px;
			}
		}

	.ag-theme-alpine
  .ag-root-wrapper-body
  .ag-checkbox-input-wrapper.ag-checked::after {
			display: block;
			background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.5 7C18.08 7 17.705 7.165 17.435 7.435L11 13.885L7.565 10.435C7.295 10.165 6.92 10 6.5 10C5.675 10 5 10.675 5 11.5C5 11.92 5.165 12.295 5.435 12.565L9.935 17.065C10.205 17.335 10.58 17.5 11 17.5C11.42 17.5 11.795 17.335 12.065 17.065L19.565 9.565C19.835 9.295 20 8.92 20 8.5C20 7.675 19.325 7 18.5 7Z' fill='white'/%3E%3C/svg%3E") !important;
			background-size: 18px;
			background-position: center;
			background-color: ${(props) => props.theme.colors.blue55};
		}
	}

	.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked:after {
		display: block;
			background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.5 7C18.08 7 17.705 7.165 17.435 7.435L11 13.885L7.565 10.435C7.295 10.165 6.92 10 6.5 10C5.675 10 5 10.675 5 11.5C5 11.92 5.165 12.295 5.435 12.565L9.935 17.065C10.205 17.335 10.58 17.5 11 17.5C11.42 17.5 11.795 17.335 12.065 17.065L19.565 9.565C19.835 9.295 20 8.92 20 8.5C20 7.675 19.325 7 18.5 7Z' fill='white'/%3E%3C/svg%3E") !important;
			background-size: 18px;
			background-position: center;
			background-color: ${(props) => props.theme.colors.blue55};
	}

	.ag-theme-alpine .ag-checkbox-input-wrapper.ag-indeterminate:after {
		display: block;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-3 10H8c-.55 0-1-.45-1-1s.45-1 1-1h8c.55 0 1 .45 1 1s-.45 1-1 1z' fill='%23206BB6 '/%3E%3C/svg%3E") !important;
			background-size: 22px;
   background-position: center;
			background-color: transparent;
	}

	.ag-theme-alpine
  .ag-root-wrapper-body
  .ag-checkbox-input-wrapper.ag-indeterminate::after {
			display: block;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-3 10H8c-.55 0-1-.45-1-1s.45-1 1-1h8c.55 0 1 .45 1 1s-.45 1-1 1z' fill='%23206BB6 '/%3E%3C/svg%3E") !important;
			background-size: 22px;
   background-position: center;
			background-color: transparent;
		}
`;
