import React, { FC } from 'react';
import { Icon } from '@flexera/ui.component-library';
import { useHelpHref } from '@flexera/shell.navigation';
import { useFNMSHelpOverride } from '@flexera/fnms.core';
import { useFNMSCapabilityCheck } from '@flexera/fnms.shared';
import { t } from 'ttag';
import { HelpIcon } from '../assets';
import { HelpIconWrapper } from '../styles';

export const HelpNavLink: FC = () => {
	const supportHref = useHelpHref();
	const { hasFNMS } = useFNMSCapabilityCheck();
	const fnmsSupportHref = useFNMSHelpOverride();
	const splashPage = window.location.pathname.includes('splash');
	const fnmsView = window.location.pathname.includes('slo');

	const handleSupportClick = () => {
		if (hasFNMS && fnmsView) {
			window.open(fnmsSupportHref, '_blank');
		} else {
			window.open(supportHref, '_blank');
		}
		return true;
	};

	return (
		<HelpIconWrapper
			id={splashPage ? 'help-icon-splash' : 'help-icon'}
			onClick={handleSupportClick}
			onKeyPress={handleSupportClick}
			tabIndex={0}
			title={t`Click here to get help via our Support Center.`}
			splashPage={splashPage}
			role={'link'}
		>
			<Icon src={HelpIcon} />
		</HelpIconWrapper>
	);
};
