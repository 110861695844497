import { useAuthenticated } from '@flexera/auth.base';
import { Permissions, usePermissionSummary } from '@flexera/lib.permissions';
import { DASHBOARDS } from '@flexera/shell.base-nav-tree';
import { loadingItem, MenuItem, useNavItems } from '@flexera/shell.navigation';
import { OrgsLoading, useCurrentOrg, useOrgId } from '@flexera/shell.orgs';
import { useEffect } from 'react';
import { t } from 'ttag';
import { helpDocBaseUrl } from '@flexera/lib.utilities';
import * as ids from './menuIds';

export function useNav() {
	const authenticated = useAuthenticated();
	// org comes from state
	const org = useCurrentOrg();
	// orgId comes from URL
	const orgId = useOrgId();
	const { addItems } = useNavItems();

	const [perms, loadingPerms] = usePermissionSummary(
		`/orgs/${org.id}`,
		Permissions.canViewCustomReport,
		Permissions.hasUnifiedObjectModel
	);

	useEffect(() => {
		if (!authenticated || !orgId) return;

		if (org === OrgsLoading || loadingPerms) {
			return addItems([loadingItem(ids.LOADING)]);
		}

		if (!perms.get(Permissions.hasUnifiedObjectModel)) return;

		const defaultRouteSet = [] as MenuItem[];

		if (perms.get(Permissions.canViewCustomReport)) {
			defaultRouteSet.push({
				id: ids.VIEW,
				parentId: DASHBOARDS,
				path: `/orgs/${orgId}/platform/reports/custom`,
				urlMatch: /^\/orgs\/\d+\/viz\/report(\/|$|\?)/,
				helpHref: `${helpDocBaseUrl}/TIP/CustomReports.htm`,
				label: t`All Reports`,
				hidden: true
			});
		}

		return addItems(defaultRouteSet);
	}, [org, orgId, authenticated, loadingPerms, perms]);
}
