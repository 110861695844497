import { useAuthenticated } from '@flexera/auth.base';
import { Permissions, usePermissionSummary } from '@flexera/lib.permissions';
import { DASHBOARDS } from '@flexera/shell.base-nav-tree';
import {
	LabelType,
	loadingItem,
	MenuItem,
	useNavItems
} from '@flexera/shell.navigation';
import { OrgsLoading, useCurrentOrg, useOrgId } from '@flexera/shell.orgs';
import { useEffect } from 'react';
import { t } from 'ttag';
import { helpDocBaseUrl } from '@flexera/lib.utilities';
import { useHideTIPlatform } from '@flexera/flexera-one.feature-flags';
import * as ids from './menuIds';

export function useNav() {
	const authenticated = useAuthenticated();
	const hideTIPlatform = useHideTIPlatform();
	// org comes from state
	const org = useCurrentOrg();
	// orgId comes from URL
	const orgId = useOrgId();
	const { addItems } = useNavItems();

	const [perms, loadingPerms] = usePermissionSummary(
		`/orgs/${org.id}`,
		Permissions.canViewHIFReports,
		Permissions.hasHIF,
		Permissions.hasITVisibility,
		Permissions.canViewCustomReports,
		Permissions.canViewITVReports,
		Permissions.hasUnifiedObjectModel
	);

	useEffect(() => {
		if (!authenticated || !orgId || hideTIPlatform) return;

		if (org === OrgsLoading || loadingPerms) {
			return addItems([loadingItem(ids.LOADING)]);
		}

		if (
			!(
				perms.get(Permissions.hasHIF) &&
				perms.get(Permissions.hasUnifiedObjectModel)
			) &&
			!(
				perms.get(Permissions.hasITVisibility) &&
				perms.get(Permissions.hasUnifiedObjectModel)
			)
		) {
			return;
		}

		const defaultRouteSet = [] as MenuItem[];

		if (
			perms.get(Permissions.canViewHIFReports) ||
			perms.get(Permissions.canViewITVReports) ||
			perms.get(Permissions.canViewCustomReports)
		) {
			defaultRouteSet.push({
				id: ids.REPORTS,
				parentId: DASHBOARDS,
				path: `/orgs/${orgId}/platform/reports`,
				urlMatch: /^\/orgs\/\d+\/platform\/reports(\/|$|\?)/,
				label: t`All Reports`,
				helpHref: `${helpDocBaseUrl}/TIP/OOTBReports.htm`,
				priority: 1,
				hasDivider: true,
				labelType: LabelType.NEW
			});
		}

		return addItems(defaultRouteSet);
	}, [org, orgId, authenticated, loadingPerms, perms, hideTIPlatform]);
}
