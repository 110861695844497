import { t } from 'ttag';
import { img1, img2, img3, img4, img5 } from './thumbnails';

export interface Video {
	id: number;
	title: string;
	description: string;
	url: string;
	thumbnail: string;
}

export const VideoPlaylistData: Video[] = [
	{
		id: 1,
		title: t`Flexera One Overview`,
		description: t`Flexera One helps you maximize value from desktop to cloud, providing you with a unified view of your technology landscape.`,
		url: `https://www.youtube.com/embed/MalvWxGEiTs`,
		thumbnail: img1.default
	},
	{
		id: 2,
		title: t`IT Visibility`,
		description: t`Flexera One’s IT Visibility gives you a single source of truth for your entire IT estate.`,
		url: `https://www.youtube.com/embed/tkRuYioZl60`,
		thumbnail: img2.default
	},
	{
		id: 3,
		title: t`SaaS Manager`,
		description: t`Flexera One’s SaaS capabilities gives you visibility and control over your SaaS landscape.`,
		url: `https://www.youtube.com/embed/mXgNlxu0sCw`,
		thumbnail: img3.default
	},
	{
		id: 4,
		title: t`Cloud Cloud Cost Optimization`,
		description: t`Flexera One’s Cloud Cost Optimization capability is a cost management and optimization solution that enables enterprises to cut costs and ensure efficiencies.`,
		url: `https://www.youtube.com/embed/C8a9W2N8iRc`,
		thumbnail: img4.default
	},
	{
		id: 5,
		title: t`IT Asset Requests`,
		description: t`Flexera One’s IT Asset Request capability enables you to rapidly deliver enterprise software to your employees.`,
		url: `https://www.youtube.com/embed/Q-sA5ganGBw`,
		thumbnail: img5.default
	}
];
