import { useAuthenticated } from '@flexera/auth.base';
import { Permissions, usePermissionSummary } from '@flexera/lib.permissions';
import { HYBRID_ITAM_FINOPS, DASHBOARDS } from '@flexera/shell.base-nav-tree';
import { loadingItem, MenuItem, useNavItems } from '@flexera/shell.navigation';
import { OrgsLoading, useCurrentOrg, useOrgId } from '@flexera/shell.orgs';
import { useEffect } from 'react';
import { t } from 'ttag';
import { IconMdCloudLicenseManagement } from '@flexera/ui.component-library';
import {
	useHideTIPlatform,
	useHIFReports
} from '@flexera/flexera-one.feature-flags';
import { helpDocBaseUrl } from '@flexera/lib.utilities';
import * as ids from './menuIds';

export function useNav() {
	const hasHIFReports = useHIFReports();
	const hideTIPlatform = useHideTIPlatform();
	const authenticated = useAuthenticated();
	// org comes from state
	const org = useCurrentOrg();
	// orgId comes from URL
	const orgId = useOrgId();
	const { addItems } = useNavItems();

	const [perms, loadingPerms] = usePermissionSummary(
		`/orgs/${org.id}`,
		Permissions.hasHIF,
		Permissions.canViewHIFReports,
		Permissions.canEmbedHIFReports,
		Permissions.hasUnifiedObjectModel
	);

	useEffect(() => {
		if (!authenticated || !orgId || hideTIPlatform) return;

		if (org === OrgsLoading || loadingPerms) {
			return addItems([loadingItem(ids.LOADING)]);
		}

		const hasHybridCapabilities =
			perms.get(Permissions.hasHIF) &&
			perms.get(Permissions.hasUnifiedObjectModel);

		if (!hasHybridCapabilities || !hasHIFReports) return;

		const defaultRouteSet = [] as MenuItem[];

		if (perms.get(Permissions.canViewHIFReports)) {
			defaultRouteSet.push(
				{
					id: HYBRID_ITAM_FINOPS,
					label: t`Cloud License Management`,
					icon: IconMdCloudLicenseManagement
				},
				{
					id: ids.POWERBIDASHBOARD,
					parentId: DASHBOARDS,
					path: `/orgs/${orgId}/platform/reports/hybrid`,
					urlMatch: /^\/orgs\/\d+\/hif\/report(\/|$|\?)/,
					hidden: true,
					helpHref: `${helpDocBaseUrl}/TIP/OOTBReports.htm`,
					label: t`All Reports`
				}
			);
		}

		if (perms.get(Permissions.canEmbedHIFReports)) {
			defaultRouteSet.push({
				id: ids.SOFTWARESPEND,
				parentId: HYBRID_ITAM_FINOPS,
				path: `/orgs/${orgId}/hif/software-insights-and-spend-optimization?mode=view`,
				urlMatch: /^\/orgs\/\d+\/hif\/software-insights-and-spend-optimization(\/|$|\?)/,
				label: t`Software Insights and Spend Optimization`,
				helpHref: `${helpDocBaseUrl}/TIP/OOTBReports.htm`,
				priority: 1
			});
		}

		return addItems(defaultRouteSet);
	}, [
		org,
		orgId,
		authenticated,
		loadingPerms,
		perms,
		hasHIFReports,
		hideTIPlatform
	]);
}
