import React from 'react';
import { Flex, BaseTheme } from '@flexera/ui.component-library';
import { t } from 'ttag';
import {
	InsightWrapper,
	MetricWrapper,
	Metric,
	MetricTitle,
	DataPointsContainer,
	MarketingDataPointsContainer,
	DataPointsWrapper,
	DataPoints
} from './Styled';
import { CardTitle, Subtitle } from '../Styled';

export const Insights = () => {
	return (
		<InsightWrapper id={'insights'}>
			<Flex flexDirection={'column'} flexBasis={'40%'}>
				<CardTitle>{t`FLEXINSIGHTS`}</CardTitle>
				<Subtitle>{t`Our Technopedia IT asset database spans:`}</Subtitle>
				<MetricWrapper>
					<Metric>4,540,000</Metric>
					<MetricTitle>{t`PRODUCTS`}</MetricTitle>
				</MetricWrapper>
				<MetricWrapper>
					<Metric>100,000</Metric>
					<MetricTitle>{t`MANUFACTURERS`}</MetricTitle>
				</MetricWrapper>
				<MetricWrapper>
					<Metric>4,500 +</Metric>
					<MetricTitle>{t`UPDATES PER DAY`}</MetricTitle>
				</MetricWrapper>
			</Flex>
			<DataPointsWrapper>
				<DataPoints id={'data-point1'} varient={'lg'}>
					<div>
						<span className={'data-point'}>1.8M</span>
						<span className={'data-title'}>{t`Software Releases`}</span>
					</div>
				</DataPoints>
				<DataPoints id={'data-point2'} varient={'sm'} ml={'250px'} mt={'65px'}>
					<div>
						<span className={'data-point'}>2.11M</span>
						<span className={'data-title'}>{t`SKUs`}</span>
					</div>
				</DataPoints>
				<DataPoints id={'data-point3'} varient={'sm'} ml={'450px'} mt={'100px'}>
					<div>
						<span className={'data-point'}>400K</span>
						<span className={'data-title'}>{t`Product Usage Rights`}</span>
					</div>
				</DataPoints>
				<DataPointsContainer>
					<MarketingDataPointsContainer>
						<Flex flexDirection={'column'} margin={'auto'} alignItems={'center'}>
							<Metric fontSize={'2.25rem'}>250,500,000+</Metric>

							<MetricTitle
								fontSize={'1.75rem'}
								fontWeight={`${BaseTheme.fontWeights.regular}`}
							>
								{t`Market Data Points`}
							</MetricTitle>
						</Flex>
					</MarketingDataPointsContainer>
				</DataPointsContainer>
				<DataPoints id={'data-point5'} varient={'sm'} mt={'435px'} ml={'125px'}>
					<div>
						<span className={'data-point'}>4.4M</span>
						<span className={'data-title'}>{t`Lifecycle Dates`}</span>
					</div>
				</DataPoints>
				<DataPoints id={'data-point6'} varient={'xl'} mt={'350px'} ml={'350px'}>
					<div>
						<span className={'data-point'}>2.7M</span>
						<span className={'data-title'}>{t`Hardware Models`}</span>
					</div>
				</DataPoints>
			</DataPointsWrapper>
		</InsightWrapper>
	);
};
